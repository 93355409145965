<template>
  <section class="py-8 bg-primary-light">
    <v-container>
      <v-row
        v-bind="{
          justify: 'center',
          'no-gutters': false,
        }"
      >
        <v-col cols="12" class="text-center">
          <h1 class="text-2xl font-bold">
            ลงทะเบียนแสดงความสนใจ
            <br />
            {{ campaignName }}
          </h1>
          <!-- <template v-if="isNotRegistered">
            <h1 class="text-2xl font-bold">
              ลงทะเบียนแสดงความสนใจ
              <br />
              {{ campaignName }}
            </h1>
          </template>
          <template v-else>
            <div class="text-2xl font-bold">
              สวัสดี! คุณ
              <span class="text-primary">
                {{ firstName }}
              </span>
            </div>
          </template> -->

          <div>
            เพื่อรับข้อเสนอพิเศษที่คาดไม่ถึง

            <!-- Incoming -->
            <!-- <template v-if="isNotRegistered && !isCountdownEnd">
              พร้อมที่จะเป็นเจ้าของ
              <span class="font-bold">
                {{ campaignName }}
              </span>
              แล้วหรือยัง?
            </template> -->

            <!-- Incoming and registed -->
            <!-- <template v-if="!isNotRegistered && !isCountdownEnd">
              อีกไม่นาน คุณจะได้เป็นเจ้าของ
              <span class="font-bold">
                {{ campaignName }}
              </span>
              แล้ว 🎉
            </template> -->

            <!-- Start campaign -->
            <!-- <template v-if="timeLeft < 0 || isCountdownEnd">
              {{ isBnn ? 'BaNANA' : 'Studio7' }}
              พร้อมให้คุณเป็นเจ้าของ
              <span class="font-bold">
                {{ campaignName }}
              </span>
              แล้ว 🎉
            </template> -->
          </div>
        </v-col>

        <v-col cols="auto pt-5">
          <template v-if="timeLeft < 0 || isCountdownEnd">
            <v-btn
              v-btn
              depressed
              large
              class="font-bold mr-3"
              height="48px"
              :dark="!isBnn"
              :color="colorBnn['color-bnn']"
              @click="$emit('click')"
            >
              {{ `เข้าสู่การจอง ${campaignName}` }}
            </v-btn>
          </template>

          <template v-else>
            <div class="pre-register-actions-container">
              <v-btn
                depressed
                large
                class="font-bold"
                width="256px"
                height="48px"
                :dark="!isBnn"
                :color="colorBnn['color-bnn']"
                @click="$emit('pre-register-form')"
              >
                ลงทะเบียนเลย
              </v-btn>

              <v-btn
                depressed
                large
                class="font-bold"
                width="256px"
                height="48px"
                outlined
                :color="colorBnn['color-bnn']"
                @click="goToCustomerInformation"
              >
                <template v-if="isNotRegistered">
                  กรอกที่อยู่จัดส่งล่วงหน้าที่นี่
                </template>

                <template v-else>
                  กรอกที่อยู่จัดส่งล่วงหน้าแล้ว
                </template>
              </v-btn>

              <!-- <add-to-calendar-button
                id="add-to-calendar-button"
                hideCheckmark
                hideTextLabelButton
                trigger="click"
                :name="calendarData.name"
                :label="calendarData.label"
                :options="calendarData.options"
                :startDate="calendarData.startDate"
                :endDate="calendarData.endDate"
                :description="calendarData.description"
                :startTime="calendarData.startTime"
                :endTime="calendarData.endTime"
                :timeZone="calendarData.timeZone"
              ></add-to-calendar-button> -->
            </div>

            <!-- <div
              v-if="!isNotRegistered"
              class="text-gray-medium-2 text-center text-sm mt-6"
            >
              หากไม่ใช่คุณหรือต้องการเปลี่ยนแปลงข้อมูล<br
                class="d-block d-sm-none"
              />ที่ลงทะเบียนไว้
              <a
                class="edit-register-form-link"
                @click="goToCustomerInformation"
              >
                กดที่นี่
              </a>
            </div> -->
          </template>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import colorBnn from '@/config/color'
import isBnn from '@/config/isBnn'

export default {
  name: 'PreRegisterSection',

  props: {
    isCountdownEnd: {
      type: Boolean,
      default: false,
    },
  },

  components: {},

  computed: {
    ...mapState({
      campaign: state => state.campaign.current,
      startedAt: state => state?.campaign?.current?.data?.started_at,
    }),

    campaignName() {
      return this.campaign?.data?.name
    },

    isNotRegistered() {
      return !localStorage.getItem('registered')
    },

    calendarData() {
      let link = `${window.location.origin}/${this.slug}/countdown`

      return {
        name: `[${isBnn ? 'BaNANA' : 'Studio7'}] PRE-ORDER ${
          this.campaign?.data?.name
        } `,
        options: "'Apple','Google','Outlook.com','iCal'",
        startDate: moment
          .utc(this.startedAt)
          .local()
          .add(-5, 'minutes')
          .format('YYYY-MM-DD'),
        endDate: moment
          .utc(this.startedAt)
          .local()
          .add(1, 'hour')
          .format('YYYY-MM-DD'),
        startTime: moment
          .utc(this.startedAt)
          .local()
          .add(-5, 'minutes')
          .format('HH:mm'),
        endTime: moment
          .utc(this.startedAt)
          .local()
          .add(1, 'hour')
          .format('HH:mm'),
        label: 'Notify Me',
        timeZone: 'Asia/Bangkok',
        description: `[p][strong]${
          isBnn ? 'BaNANA' : 'Studio7'
        } | PRE-ORDER ${
          this.campaign?.data?.name
        }[/strong][/p][hr][p]เริ่ม ${moment
          .utc(this.startedAt)
          .local()
          .format(
            'DD MMMM YYYY เวลา HH:mm น.',
          )}[p][url]${link}|${link}[/url][/p][hr]`,
      }
    },

    data() {
      return this.$store.getters['user/getData']
    },

    firstName() {
      return this.data?.first_name || ''
    },
  },

  data() {
    return {
      // Import
      colorBnn,
      isBnn,
      // ----------------

      // Data
    }
  },

  mounted() {
    this.$store.dispatch('user/fetchData')
  },

  methods: {
    goToCustomerInformation() {
      this.$router.push({
        name: 'CustomerInformation',
        params: { slug: this.$route?.params?.slug },
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.edit-register-form-link{
	color: $color-bnn;
	text-direction: underline;
}
.text-gray-medium-2{
	color: $color-bnn-gray-medium-2;
}
.btn-bnn-default{
  padding: 0 1.25rem !important
  font-size: 1rem !important
}
add-to-calendar-button#add-to-calendar-button::part(atcb-button){
	background-color: #fff
	box-shadow: 0 0 0 1px $color-bnn-gray-light
	font-size: 0.875rem
	margin: 0
	border: none

	// v-btn
	align-items: center;
	border-radius: 50%;
	display: inline-flex;
	flex: 0 0 auto;
	font-weight: 500;
	letter-spacing: 0.0892857143em;
	justify-content: center;
	outline: 0;
	position: relative;
	text-decoration: none;
	text-indent: 0.0892857143em;
	text-transform: uppercase;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	height: 48px;
    width: 48px;

	transition: 0.2s ease-in-out;
	&:hover {
		width: 48px
		background-color: $color-bnn-light
		transition: 0.2s ease-in-out;
		box-shadow: 0 0 0 1px $color-bnn-mid
	}
}

.btn-animate:after{
	position: absolute;
    box-shadow: 0 0 1px 2px $color-bnn-mid;
    border-top: 10px solid $color-bnn-mid;
    border-right: 20px solid $color-bnn-mid;
    border-bottom: 10px solid $color-bnn-mid;
    border-left: 20px solid $color-bnn-mid;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    animation: 1.5s ease-in-out infinite btn-primary-effect;
    content: ""
}

@keyframes btn-primary-effect {
	0%{
		transform:scale(1);
		opacity:0
	}
	50%{
		opacity: 0.75
	}
	to{
		transform: scale(1.1, 1.3);
		opacity:0
	}
}

.pre-register-actions-container {
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 16px
}
</style>
